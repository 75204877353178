<template>
    <el-dialog
        :visible.sync="modalVisible"
        :before-close="closeModal"
        width="600px"
        top="20px"
        :close-on-click-modal="false"
        @open="getDetails"
        @closed="resetForm"
    >
        <span slot="title">
            <div class="absolute w-full top-0 inset-x-0 border-b rounded-t-lg p-4 bg-gray-100">
                <p>
                    {{ $t('clients.edit_damage') }}
                </p>
            </div>
        </span>

        <div v-loading="$wait.is('loading.damage')" class="flex justify-center mb-14 mt-8 px-12" element-loading-spinner="el-custom-spinner">
            <el-form ref="form" :model="formData" label-position="top" :rules="rules" class="w-full">
                <el-form-item :label="$t('clients.status')">
                    <el-select v-model="formData.status" class="w-full">
                        <el-option :value="0" :label="$t('clients.open')" />
                        <el-option :value="1" :label="$t('clients.solved')" />
                    </el-select>
                </el-form-item>

                <el-form-item :label="$t('clients.description')" prop="description">
                    <el-input
                        v-model="formData.description"
                        type="textarea"
                        :rows="3"
                        resize="none"
                    />
                </el-form-item>

                <el-form-item :label="$t('clients.amount')" prop="amount">
                    <money v-model="formData.amount" class="el-input__inner" v-bind="money" />
                </el-form-item>

                <el-form-item :label="$t('clients.employee')" class="w-full">
                    <EmployeesDropdown
                        ref="employeeDropdown"
                        :withArchive="false"
                        :withoutQuerySearch="true"
                        :multiple="false"
                        :preSelectedEmployee="formData.employeeUuid"
                        customClass="w-full"
                        @selected="employeeSelected"
                    />
                </el-form-item>
            </el-form>
        </div>

        <div class="flex justify-end rounded-b-lg border-t w-full p-3 bg-gray-100 absolute bottom-0 inset-x-0">
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeModal">{{ $t('common.cancel') }}</el-button>
                <el-button type="primary" :loading="$wait.is('loading.edit_damage')" @click="update">{{ $t('common.update') }}</el-button>
            </span>
        </div>
    </el-dialog>
</template>
<script>
import Vue from 'vue';
import { Money } from 'v-money';
import Api from './damages.api';

Vue.use(Money);

export default {
    components: {
        Money,
        EmployeesDropdown: () => import(/* webpackChunkName: "EmployeesDropdown" */ '@/components/EmployeesDropdown'),
    },

    props: {
        modalVisible: {
            type: Boolean,
        },
        damageUuid: {
            type:    String,
            default: '',
        },
    },

    data() {
        return {
            formData: {
                employee:     '',
                description:  '',
                status:       0,
                amount:       0,
                employeeUuid: null,
            },
            money: {
                decimal:   ',',
                thousands: '',
                precision: 2,
                masked:    true,
            },
            rules: {
                amount:      [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                description: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
            },
        };
    },

    methods: {
        async getDetails() {
            try {
                this.$wait.start('loading.damage');
                const data = await Api.getDetails(this.damageUuid);
                this.formData.status = data.status;
                this.formData.amount = data.amount / 100;
                this.formData.description = data.description;
                this.formData.employee = `${data.employee_name} ${data.employee_surname}`;
                this.formData.employeeUuid = data.employee_uuid;
            } finally {
                this.$wait.end('loading.damage');
            }
        },

        async update() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));
            this.$wait.start('loading.edit_damage');
            try {
                await Api.update(this.damageUuid, this.formData);
                this.$notify.success({ title: this.$t('common.success') });
                this.$emit('refresh');
            } finally {
                this.$wait.end('loading.edit_damage');
                this.closeModal();
            }
        },

        employeeSelected(uuid) {
            this.formData.employeeUuid = uuid;
        },

        closeModal() {
            this.$emit('close');
        },

        resetForm() {
            this.$refs.form.resetFields();
        },
    },
};
</script>
